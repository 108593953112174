.detailCardRoot {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 80%;
  max-width: 1200px;
  height: 800px;
  z-index: 1000;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
}

.detailCardMediaContainer {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.detailCardContent {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; /* 确保在卡片下面 */
}
